export const generateRandomColour = () => {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color + '80'
}

export const colourSpinner = (color, range) => {
  const baseColour = color
  const divisions = 20
  const colours = []
  for (var i = 0; i < range; i++) {
    const value = 99 - i * divisions
    const newColour = value < 10 ? `0${value}` : value
    colours.push(`${baseColour}${newColour}`)
  }
  return colours
}

export const blueColourConstants = [
  '#6C8093',
  '#4D6D8E',
  '#22364B',
  '#2C4762',
  '#365779',
]

export const coloursByTelco = {
  mtn: '#FFC500',
  airtel: '#EA1B21',
  glo: '#008C00',
  unknown: '#AAAAAA',
  '9mobile': '#006348',
}

export const coloursByCountry = {
  canada: '#C9251B',
  'united kingdom': '#041D5B',
  nigeria: '#008C00',
  unknown: '#AAAAAA',
}

export const colours = () => {
  var colors = []
  while (colors.length < 100) {
    do {
      var color = Math.floor((Math.random() * 1000000) + 1)
    } while (colors.indexOf(color) >= 0)
    colors.push('#' + ('000000' + color.toString(16)).slice(-6))
  }

  return colors
}
