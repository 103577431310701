<template>
  <s-card class="stats-card pa-4 pa-lg-6 overflow-hidden">
    <div class="d-flex justify-space-between align-center">
      <s-text weight="bold" size="md-m" class="d-flex">
        Online users
        <v-btn
          depressed
          icon
          class="refresh-btn"
          @click="getStats(true)"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </s-text>
    </div>

    <div class="d-flex align-center mt-4" v-if="!isWorking">
      <v-row>
        <v-col>
          <s-text weight="bold" size="m-lg" color="primary">
            <ICountUp
              :delay="countup.delay"
              :endVal="userCount"
              :options="countup.options"
            />
          </s-text>
          <s-link
            :to="{ name: 'onlineConnections' }"
            class="d-flex text--primary"
          >
            View <s-icon name="arrow-circle-right" class="ml-1" />
          </s-link>
        </v-col>
      </v-row>
    </div>

    <div v-else class="d-flex pa-12">
      <v-progress-circular
        indeterminate
        class="ma-auto"
        color="primary"
        :size="20"
        :width="3"
      />
    </div>
  </s-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ICountUp from 'vue-countup-v2'
export default {
  name: 'TopStatsCard',
  components: {
    ICountUp,
  },
  computed: {
    ...mapGetters({
      userCount: 'analytic/userConnectedOnlineCount',
    }),
  },
  data () {
    return {
      timex: null,
      shouldPoll: false,
      isWorking: true,
      interval: null,
      countup: {
        delay: 500,
        options: {
          useEasing: true,
          useGrouping: true,
          separator: ',',
          decimal: '.',
        },
      },
    }
  },
  mounted () {
    this.getStats()
  },
  methods: {
    cancelPoll () {
      clearInterval(this.timex)
    },
    getStats (override = false) {
      this.cancelPoll() // clear until response
      if (this.shouldPoll === false && this.isWorking === false && override === false) return
      const payload = { key: 'set_user_connected_online', params: { online: true } }
      this.isWorking = true
      this.$store
        .dispatch('analytic/getUsersByFilter', payload)
        .finally(() => {
          this.isWorking = false
          this.timex = setInterval(() => this.getStats(), 30000)
        })
    },
  },
  destroyed () {
    this.cancelPoll()
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.stats-card {
  border-bottom: $thin-borderline;
}

.stats-card__referesh-btn {
  font-size: $text-sm !important;
}

.refresh-btn {
  margin-top: -3px;
}

.stats-card__header {
  cursor: default;
}
</style>
