<template>
  <s-card
    class="time_series pa-6 pb-10 bottom-thin-border relative mb-6"
  >
    <div
      class="block_loader d-flex align-center justify-center"
      v-if="isWorking"
    >
      <v-progress-circular
        indeterminate
        class="ma-auto"
        color="primary"
        :size="20"
        :width="3"
      />
    </div>

    <div class="d-flex justify-space-between align-center mb-1">
      <s-text
        weight="bold"
        size="md-m"
      >
        {{ title }}
      </s-text>

      <div class="d-flex justify-end align-center">
        <s-btn
          outlined
          elevation="0"
          color="primary"
          class="refresh-btn sm-d-none"
          small
          @click="refresh()"
        >
          Refresh
        </s-btn>
      </div>
    </div>

    <empty-state
      v-if="chartData.length === 0"
      message="No data available"
      hint="Please check back."
      style="height: 350px"
      class="ma-auto"
    />
    <apexchart
      v-else
      type="area"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </s-card>
</template>

<script>
import { mapState } from 'vuex'
import EmptyState from '@/components/cards/EmptyState'
import VueApexCharts from 'vue-apexcharts'
import { removeSpecialChars, formatToGb } from '@/utils/fmt'
import { DEFAULT_DATE_RANGE } from '../../utils/constants'
import { coloursByTelco } from '../../utils/colours'

export default {
  name: 'MultiTimeseriesAnalytics',
  props: {
    title: {
      type: String,
      required: true,
    },
    _key: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    apexchart: VueApexCharts,
    'empty-state': EmptyState,
  },
  computed: {
    ...mapState({
      chartData (state, getters) {
        return getters[`analytic/timeSeriesMetricsBy${removeSpecialChars(this._key)}`]
      },
    }),
    allTelcos: function () {
      if (this.chartData.length === 0) return null
      const tempSeries = this.chartData
      // Extract unique telcos
      const uniqueTelcos = new Set()
      tempSeries.forEach(entry => {
        entry.telcos.forEach(telcoEntry => {
          uniqueTelcos.add(telcoEntry.telco)
        })
      })
      // Convert set to array
      const telcos = [...uniqueTelcos].sort()
      return telcos
    },
    telcoColors: function () {
      if (this.chartData.length === 0) return null
      const setTelcoColors = this.allTelcos.map(telco => {
        return coloursByTelco[telco.toLowerCase()] || coloursByTelco.unknown
      })
      return setTelcoColors
    },
    series: function () {
      if (this.chartData.length === 0) return null
      const tempSeries = this.chartData
      // Convert set to array
      const series = this.allTelcos.map(option => {
        return {
          name: option,
          data: tempSeries
            .map(data => {
              const count = formatToGb(data?.telcos?.find(list => list.telco === option)?.usage || 0)
              return count.toFixed(2)
            })
            .splice(0, DEFAULT_DATE_RANGE),
        }
      })
      return series
    },
    xAxis: function () {
      if (this.chartData.length === 0) return null
      // get the times
      const values = this.chartData
      const tempTime = values.map(seriesValue => {
        return new Date(seriesValue.date).toISOString()
      })

      // only return values for 1 month
      if (tempTime.length > DEFAULT_DATE_RANGE) return tempTime.splice(0, DEFAULT_DATE_RANGE)

      return tempTime
    },
    chartOptions: function () {
      return {
        chart: {
          id: 'content-request-chart',
          height: 350,
          type: 'area',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: this.telcoColors,
        stroke: {
          curve: 'smooth',
        },
        yaxis: {
          title: {
            text: 'Usage (Gb)',
          },
          labels: {
            style: this.labelFontStyle,
          },
        },
        xaxis: {
          type: 'datetime',
          categories: this.xAxis,
          title: {
            text: 'Date',
          },
          axisBorder: {
            show: this.showAxisLabel,
          },
          axisTicks: {
            show: this.showAxisLabel,
          },
        },
        tooltip: {
          x: {
            show: this.showAxisLabel,
            format: 'dd/MM/yy HH:mm',
          },
          style: this.labelFontStyle,
        },
        legend: {
          horizontalAlign: 'right',
          ...this.labelFontStyle,
          labels: {
            colors: this.labelColors,
          },
          markers: {
            width: 10,
            height: 10,
          },
          itemMargin: {
            horizontal: 10,
            vertical: 15,
          },
        },
      }
    },
  },
  data () {
    return {
      filterBy: 'oneDayAgo',
      startDate: '',
      endDate: '',
      isWorking: false,
      labelFontStyle: {
        fontSize: '13px',
        fontWeight: 600,
        fontFamily: 'Questrial, sans-serif',
      },
      labelColors: ['#000'],
    }
  },
  methods: {
    removeSpecialChars,
    formatToGb,
    getMetrics () {
      this.isWorking = true
      this.$store
        .dispatch('analytic/getTimeSeriesMetrics', {
          key: this._key.toLowerCase(),
          params: {
            series: this._key.toLowerCase(),
          },
        })
        .finally(() => {
          this.isWorking = false
        })
    },
    refresh () {
      this.getMetrics()
    },
  },
  mounted () {
    this.getMetrics()
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_variables.scss';

.time_series {
  height: 450px !important;
  min-height: 450px !important;
}

.block_loader {
  z-index: 10;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(249, 208, 208, 0.2);
  cursor: progress;
  top: 0;
  left: 0;
}

.refresh-btn {
  font-size: $text-sm !important;
}
</style>
