<template>
  <div>
    <loader
      v-if="!connections"
      message="Getting Active Connections ...."
    />
    <div v-else>
      <v-container>
        <div
          class="d-flex flex-column align-center justify-center scroll-y px-4"
        >
          <v-row
            class="pa-0 ma-0 mb-3 app-card"
            v-for="user in connections"
            :key="user.id"
            @click="viewUser(user.user_id)"
          >
            <v-col class="" cols="1">
              <v-icon v-if="user.verified" color="green">mdi-check</v-icon>
            </v-col>
            <v-col class="d-flex flex-column" cols="5">
              <s-text
                weight="medium"
                color="grayTextAlt"
              >
                {{ isUsernameNumeric(user.username) }}{{ user.username }}
              </s-text>
            </v-col>
            <v-col class="d-flex flex-column" cols="5">
              <div>
                {{ user.created_at }}
              </div>
            </v-col>
            <v-col class="action_group d-flex align-center" cols="1">
              <s-icon name="arrow-circle-right" />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/cards/Loader'
import { isUsernameNumeric } from '@/utils/fmt'
export default {
  name: 'ActiveConnections',
  components: {
    loader: Loader,
  },
  computed: {
    ...mapGetters({
      connections: 'analytic/userConnectedSince',
    }),
  },
  methods: {
    isUsernameNumeric,
    viewUser (id) {
      this.$router.push({ name: 'appDetails', params: { id: id } })
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.bg-white {
  background: white;
}
</style>
