<template>
  <div>
    <loader
      v-if="!connections"
      message="Getting Online Connections ...."
    />
    <div v-else>
      <v-container>
        <div
          class="d-flex flex-column align-center justify-center scroll-y px-4"
        >
          <v-row
            class="pa-0 ma-0 mb-3 app-card app-card-content"
            v-for="user in connections"
            :key="user.id"
            @click="viewUser(user.user_id)"
          >
            <v-col class="" cols="1">
              <v-icon v-if="user.verified" color="green">mdi-check</v-icon>
            </v-col>
            <v-col class="d-flex flex-column" cols="5">
              <s-text
                weight="medium"
                color="grayTextAlt"
              >
                {{ isUsernameNumeric(user.username) }}{{ user.username }}
              </s-text>
            </v-col>
            <v-col class="d-flex flex-column" cols="5">
              <div>
                {{ user.created_at }}
              </div>
            </v-col>
            <v-col class="action_group d-flex align-center" cols="1">
              <div class="floating d-flex align-center absolute pr-3 r-10" style="right: 0;">
                <s-btn
                  outlined
                  elevation="0"
                  color="grayTextAlt"
                  x-small
                  @click.stop="disconnectUser(user.user_id)"
                  :style="{ fontSize: '12px', background: '#ffffff' }"
                  class="mr-2"
                >
                  Disconnect
                </s-btn>
                <s-btn
                  elevation="0"
                  color="primary"
                  x-small
                  @click.stop="viewUser(user.user_id)"
                  :style="{ fontSize: '12px' }"
                  class="mr-2"
                >
                  View User
                </s-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/cards/Loader'
import { isUsernameNumeric } from '@/utils/fmt'
export default {
  name: 'ActiveConnections',
  components: {
    loader: Loader,
  },
  computed: {
    ...mapGetters({
      connections: 'analytic/userConnectedOnline',
    }),
  },
  methods: {
    isUsernameNumeric,
    viewUser (id) {
      this.$router.push({ name: 'appDetails', params: { id: id } })
    },
    getStats () {
      const payload = { key: 'set_user_connected_online', params: { online: true } }
      this.$store
        .dispatch('analytic/getUsersByFilter', payload)
    },
    disconnectUser (userId) {
      this.$store
        .dispatch('user/updateUserConnection', { userId, params: { action: 'disconnect' } })
        .finally(() => {
          this.getStats()
        })
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.bg-white {
  background: white;
}
</style>
